import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
} from "@mui/material";
import { Deployment } from "../../../../types/Deployment";
import { useFetchDeploymentsBySiteId } from "../../../../hooks/useFetchDeployments";
import { useEffect, useMemo } from "react";

interface Props {
    onDeploymentChange: (deployment: Deployment) => void;
    siteId: string;
}

export default function SeasonSelector({ onDeploymentChange, siteId }: Props) {
    const { deployments, isLoading, isError } =
        useFetchDeploymentsBySiteId(siteId);
    const queryKey = `deployments_${siteId}`;

    const sortedDeployment = useMemo(
        () =>
            deployments
                ? [...deployments].sort(
                      (a, b) =>
                          new Date(b.created).getTime() -
                          new Date(a.created).getTime(),
                  )
                : [],
        [deployments],
    );

    useEffect(() => {
        if (deployments && deployments.length > 0) {
            const savedDisplayName = localStorage.getItem(queryKey);

            const selectedDeployment = savedDisplayName
                ? deployments.find((d) => d.displayName === savedDisplayName)
                : sortedDeployment[0];

            if (selectedDeployment) {
                localStorage.setItem(queryKey, selectedDeployment.displayName);
                onDeploymentChange(selectedDeployment);
            }
        }
    }, [sortedDeployment, deployments, queryKey, onDeploymentChange]);

    const selectedDeploymentDisplayName =
        localStorage.getItem(queryKey) ||
        (sortedDeployment.length > 0 ? sortedDeployment[0].displayName : "");

    const handleChange = (event: SelectChangeEvent<string>): void => {
        const deployment = deployments?.find(
            (d) => d.displayName === event.target.value,
        );

        if (deployment) {
            // eslint-disable-next-line no-undef
            localStorage.setItem(queryKey, deployment.displayName);
            onDeploymentChange(deployment);
        }
    };

    if (isLoading) {
        return (
            <Skeleton sx={{ m: 1, minWidth: 200, minHeight: 100 }}></Skeleton>
        );
    }

    if (isError) {
        return (
            <FormControl
                variant="outlined"
                error={true}
                sx={{ m: 1, minWidth: 200 }}
            >
                <InputLabel id="season-select-label">Error</InputLabel>
                <Select
                    labelId="season-select-label"
                    id="season-select"
                    value=""
                    label="Error"
                    disabled
                    autoWidth
                    size="small"
                >
                    <MenuItem value="">Error loading seasons</MenuItem>
                </Select>
            </FormControl>
        );
    }
    return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="season-select-label">Season</InputLabel>
            <Select
                labelId="season-select-label"
                id="season-select"
                value={selectedDeploymentDisplayName}
                label="Season"
                onChange={handleChange}
                size="small"
            >
                {sortedDeployment?.map((deployment) => (
                    <MenuItem
                        key={deployment.id}
                        value={deployment.displayName}
                    >
                        {deployment.displayName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
