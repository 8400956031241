import { FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";
import { ChangeEvent } from "react";

export const ConfidenceIntervalSwitch = ({
    checked,
    onChange,
    disabled,
    deploymentSeason,
}: {
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    deploymentSeason: string;
}) => {
    const tooltipTitle =
        deploymentSeason === ""
            ? `No season selected`
            : `The ${deploymentSeason} season does not have enough data to calculate Confidence Intervals`;
    return (
        <FormGroup>
            <Tooltip title={disabled ? tooltipTitle : ""}>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={onChange}
                            checked={checked}
                            disabled={disabled}
                        />
                    }
                    label="Confidence Intervals"
                    sx={{
                        marginRight: "0px",
                    }}
                />
            </Tooltip>
        </FormGroup>
    );
};
