import ReactApexChart from "react-apexcharts";
import "./Graph.css";
import { ApexOptions } from "apexcharts";
import React from "react";

interface GraphProps {
    options: ApexOptions;
    series?: {
        name: string;
        type: string;
        zIndex: number | undefined;
        data: any[];
    }[];
    height?: number;
    width?: string | number;
}

const Graph: React.FC<GraphProps> = ({
    options,
    series = [],
    height = 400,
    width = "97%",
}) => {
    const validSeries = series?.length > 0 ? series : [{ data: [] }];

    return (
        <ReactApexChart
            options={options}
            series={validSeries}
            height={height}
            width={width}
            type={options.chart?.type || "line"}
            key={`${options.chart?.type || "line"}-${validSeries.length}`}
        />
    );
};

export default Graph;
