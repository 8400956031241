import { useState, useEffect } from "react";
import { v1TaglessService } from "../services/Services";
import { Device } from "../types/Device";
import { useUserContext } from "../providers/useUserProvider";
import { Deployment } from "../types/Deployment";

interface ErrorState {
    hasError: boolean;
    message: string;
}

export const useSiteDevices = (
    setErrorState: (error: ErrorState) => void,
    deployment: Deployment,
) => {
    const { selectedSite } = useUserContext();
    const [devices, setDevices] = useState<Device[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadDevices = async () => {
            if (!selectedSite || !deployment){
                setDevices([]);
                setIsLoading(false);
                return;
            }

            setIsLoading(true);

                try {
                    const deviceResponse =
                        await v1TaglessService.getDevicesBySiteID(
                            selectedSite.id,
                            deployment.id,
                        );
                    setDevices(deviceResponse || []);
                } catch (error) {
                    setErrorState({
                        hasError: true,
                        message: `Unable to load devices for ${selectedSite.displayName}`,
                    });
                    console.log("Error:", error);
                    setDevices([]);
                } finally {
                    setIsLoading(false);
                }
            
        };

    loadDevices();
    }, [selectedSite, setErrorState, deployment]);  

    return { devices, isLoading };
};
