import { CountType } from "./Count";
import { Direction } from "./Direction";

export interface Pipeline {
    id: string;
    models: string[];
    metrics: Metric[];
}

export interface Metric {
    name: string;
    value: number;
}

export type PipelineMetrics = Record<string, Metric[]>;

export const DEFAULT_BIAS = 1;
export const DEFAULT_MoE = 0;

export class PipelineMetricMap {
    private map: Record<string, Record<string, number>> = {};

    constructor(metrics: PipelineMetrics) {
        Object.keys(metrics).forEach((pipelineId: string) => {
            this.map[pipelineId] = {};
            metrics[pipelineId].forEach((metric: Metric) => {
                this.map[pipelineId][metric.name] = metric.value;
            });
        });
    }

    private getDirectionalMetric(
        pipelineId: string,
        direction: Direction,
        suffix: string,
    ): number | undefined {
        return this.map[pipelineId]?.[`${direction.toLowerCase()}_${suffix}`];
    }

    getBias(pipelineId: string, direction: Direction): number | undefined {
        return (
            this.getDirectionalMetric(pipelineId, direction, "bias") ??
            DEFAULT_BIAS
        );
    }

    getMarginOfError(pipelineId: string, direction: Direction): number | undefined {
        return (
            this.getDirectionalMetric(pipelineId, direction, "ci95") ??
            DEFAULT_MoE
        );
    }

    isEmpty(): boolean {
         // returns false iff all pipelines have no metrics
         return Object.entries(this.map).every(([key, value]) => value === null || Object.keys(value).length === 0);
    }
}
