import "./Report.css";
import { Box, Container, Tab, Tabs } from "@mui/material";
import {
    MemoizedTabPanel,
    a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { useCallback, useEffect, useState } from "react";
import DailyReport from "./DailyReport";
import SummaryReport from "./SummaryReport";
import { ConfidenceIntervalSwitch } from "./Switches/ConfidenceIntervalSwitch";

import { useSiteDevices } from "../../../hooks/useSiteDevices";
import { useUserContext } from "../../../providers/useUserProvider";
import moment from "moment";
import SeasonSelector from "./Dropdowns/SeasonSelector";

const CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY = "confidence_interval_status";

const userPrefersCIs = () => {
    return localStorage.getItem(CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY) === "true"
}

const Report = ({ setErrorState }) => {
    const { selectedSite, timezone } = useUserContext();
    const [activeTab, setActiveTab] = useState(0);
    const [selectedDate, setSelectedDate] = useState(moment().tz(timezone));
    const [currentDeployment, setCurrentDeployment] = useState(null);
    const [ciDisabled, setCIDisabled] = useState(false)
    const [ciToggled, setCIToggled] = useState(
        userPrefersCIs() && currentDeployment !== null && !ciDisabled,
    );

    const handleSwitchDisable = (value) => {
        setCIToggled(value ? false : ciToggled)
        setCIDisabled(value);
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const toggleConfidenceIntervals = () => {
        const newValue = !ciToggled;
        setCIToggled(newValue);
        localStorage.setItem(
            CONFIDENCE_INTERVAL_LOCAL_STORAGE_KEY,
            String(newValue),
        );
    };

    const handleDeploymentChange = useCallback(
        (deployment) => {
            setCIDisabled(false)
            setCIToggled(userPrefersCIs())
            setCurrentDeployment(deployment);
        },
        [selectedSite],
    );

    const {devices, isLoading} = useSiteDevices(setErrorState, currentDeployment);
    
    useEffect(() => {
        if (currentDeployment) {
            setSelectedDate(moment.tz(currentDeployment.startTime, timezone));
        }
        setCIDisabled(false)
    }, [currentDeployment, timezone]);

    if (!selectedSite) return null;
    if (isLoading) return <div>Loading devices...</div>;

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <SeasonSelector
                        onDeploymentChange={handleDeploymentChange}
                        siteId={selectedSite.id}
                    />
                </Container>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="report tabs"
                    >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Daily Report" {...a11yProps(1)} />
                    </Tabs>
                    {activeTab === 0 && (
                        <ConfidenceIntervalSwitch
                            checked={ciToggled}
                            onChange={toggleConfidenceIntervals}
                            disabled={ciDisabled}
                            deploymentSeason={currentDeployment ? currentDeployment.displayName : ""}
                        />
                    )}
                </Container>
            </Box>

            <MemoizedTabPanel value={activeTab} index={0}>
                <SummaryReport
                    devices={devices}
                    setErrorState={setErrorState}
                    enableCI={ciToggled}
                    deployment={currentDeployment}
                    setCIDisabled={handleSwitchDisable}
                />
            </MemoizedTabPanel>
            <MemoizedTabPanel value={activeTab} index={1}>
                <DailyReport
                    devices={devices}
                    setErrorState={setErrorState}
                    activeTab={activeTab}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    deployment={currentDeployment}
                />
            </MemoizedTabPanel>
        </Box>
    );
};

export default Report;
