import { useQuery } from "@tanstack/react-query";
import { v1TaglessService } from "../services/Services";

export const useFetchDeploymentsBySiteId = (siteId: string) => {
    const queryKey = ["deployments", siteId];

    const {
        data: deployments = [],
        error,
        isLoading,
        isError,
        isSuccess,
    } = useQuery({
        queryKey: queryKey,
        queryFn: () => v1TaglessService.getDeploymentBySiteId(siteId),
        retry: 1,
    });

    return {
        deployments,
        isLoading,
        isError,
        error,
        isSuccess,
    };
};
