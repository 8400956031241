import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";

export const DatePicker = ({ value, onChange, timezone, minDate, maxDate }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
                label="Select a Date"
                value={moment.tz(value, timezone)}
                onChange={onChange}
                minDate={moment.tz(minDate, timezone)}
                maxDate={maxDate ? moment.tz(maxDate, timezone) : null}
            />
        </LocalizationProvider>
    );
};
